import React, { useState } from 'react';
import axios from 'axios';
import SearchDataTable from './searchDataTable';
import ChromosomeList from './chromList';
import About from './about';

function Spinner() {
    return (
        <div className="spinner-container d-flex align-items-center justify-content-center">
            <div className="spinner-border text-secondary m-5" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}

function VariantBrowser() {
    const [searchBy, setSearchBy] = useState('coordinates');
    const [chromValue, setChromValue] = useState('');
    const [startPosValue, setStartPosValue] = useState('');
    const [endPosValue, setEndPosValue] = useState('');
    const [geneValue, setGeneValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchMessage, setSearchMessage] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(false);

    function handleChromValueChange(event) {
        setChromValue(event.target.value);
    }

    function handleStartPosValueChange(event) {
        setStartPosValue(event.target.value);
    }

    function handleEndPosValueChange(event) {
        setEndPosValue(event.target.value);
    }

    function handleGeneValueChange(event) {
        setGeneValue(event.target.value);
    }

    function handleSearch(event) {
        event.preventDefault();
        // reset state values
        setSearchResults([]);
        setSearchMessage('');
        setToastMessage('');
        // fetch data from API
        setFetchingData(true);
        // set request body based on search context
        let requestBody = {};
        // search by coordinates
        if (searchBy === 'coordinates') {
            // validate if input values are valid
            if (!chromValue || !startPosValue || !endPosValue) {
                setSearchMessage('Please enter valid values.');
                return;
            }
            const startPos = startPosValue.replace(/,/g, '');
            const endPos = endPosValue.replace(/,/g, '');
            if (parseInt(startPos) > parseInt(endPos)) {
                setSearchMessage('End Position should be greater than Start Position.');
                return;
            }
            if (isNaN(parseInt(startPos)) || isNaN(parseInt(endPos))) {
                setSearchMessage('Please enter valid numeric values.');
                return;
            }

            requestBody = {
                filters: [
                    {
                        chrom: chromValue,
                        pos: {
                            start: parseInt(startPos),
                            end: parseInt(endPos),
                        },
                    }
                ],
                selected_fields: ['gene', 'pos', 'ref', 'alt', 'type', 'af', 'an', 'ac_het', 'ac_hom', 'ac_hemi', 'ac', 'filter'],
                size: 10000,
            };
        }
        // search by gene
        if (searchBy === 'gene') {
            let genes = [];
            // validate if input values are valid
            if (!geneValue) {
                setSearchMessage('Please enter valid values.');
                return;
            }
            // transform gene string value to array
            geneValue.toUpperCase().split(',').forEach((gene) => {
                genes.push(gene.trim());
            });
            requestBody = {
                filters: [
                    {
                        gene: genes,
                    }
                ],
                selected_fields: ['gene', 'pos', 'ref', 'alt', 'type', 'af', 'an', 'ac_het', 'ac_hom', 'ac_hemi', 'ac', 'filter'],
                size: 10000,
            };
        }
        // fetch data from API
        // if found, display the data in the table
        // if not found, display a message that the data was not found
        const apiUrl =
            process.env.NODE_ENV === 'production'
            ? '/search'
            : 'https://variants.gregorconsortium.org/search';
        return axios.post(apiUrl, requestBody)
            .then((response) => {
                if (response.data.error) {
                    setSearchMessage(response.data.error);
                }
                // set search results if data is found
                if (!response.data.results.length) {
                    setSearchResults([]);
                    setSearchMessage(searchBy === 'coordinates' ? 'No variants found in the given coordinates.' : 'No variants found for the given gene(s).');
                }
                if (response.data.results && response.data.results.length) {
                    console.log('Data found');
                    setToastMessage(`${response.data.results.length} records found.`);
                    setSearchResults(response.data.results);
                    setSearchMessage('');
                }
                // reset fetching data state
                setFetchingData(false);
            }
            )
            .catch((err) => {
                setSearchMessage(`${err.name}: ${err.message}`);
                // reset fetching data state
                setFetchingData(false);
            }
        );
    }

    function handleReset() {
        setSearchBy('coordinates');
        setChromValue('');
        setStartPosValue('');
        setEndPosValue('');
        setGeneValue('');
        setSearchResults([]);
        setSearchMessage('');
        setToastMessage('');
        setFetchingData(false);
    }

    // render search and reset buttons
    function renderSearchButtons() {
        return (
            <div className="search-submit-container d-flex justify-content-end mt-2 mt-lg-0">
                <button type="submit" className="btn btn-primary mx-3">
                    Search
                </button>
                <button type="button" className="btn btn-secondary" onClick={() => handleReset()}>
                    Reset
                </button>
            </div>
        );
    }

    return (
        <div className="app-main container-fluid px-4 flex-grow-1">
            <div className="page-title d-flex align-items-center justify-content-between">
                <div className="page-title-container d-flex align-items-start">
                    <h1 className="position-relative">
                        Variant Browser (hg38)
                    </h1>
                    <span className="badge rounded-pill bg-secondary badge-beta">Beta</span>
                </div>
                {toastMessage && (
                    <div className="alert alert-info alert-dismissible fade show mb-0" role="alert">
                        <div className="text-nowrap">
                            <strong>{toastMessage}</strong>
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setToastMessage('')}></button>
                    </div>
                )}
            </div>
            <p className="description">
                Variants for chromosome X, Y, and M are currently not available in this version. We are working on adding support for these chromosomes in a future release.
            </p>
            <form id="variant-search-form" className="variant-search-form" onSubmit={(event) => handleSearch(event)}>
                <div className="variant-browser-container mt-3 mb-4">
                    <div className="search-context-selection-container d-flex align-items-center mb-3">
                        <div className="search-context-label me-3 fw-bold">Search by:</div>
                        <div className="form-check me-3">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="searchBySelect"
                                id="searchByCoordinates"
                                checked={searchBy === 'coordinates'}
                                onChange={() => setSearchBy('coordinates')}
                            />
                            <label className="form-check-label" htmlFor="searchByCoordinates">
                                Coordinates
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="searchBySelect"
                                id="searchByGene"
                                checked={searchBy === 'gene'}
                                onChange={() => setSearchBy('gene')}
                            />
                            <label className="form-check-label" htmlFor="searchByGene">
                                Gene
                            </label>
                        </div>
                    </div>
                    {searchBy === 'coordinates' && (
                        <div className="chr-pos-search-item w-100">
                            <div className="variant-search-ui-container d-flex align-items-end flex-lg-row flex-column">
                                <div className="flex-grow-1 row">
                                    <div className="form-group col-12 col-lg-4 col-md-12 mt-2 mt-lg-0">
                                        <label htmlFor="chromosome" className="fw-bold">Chromosome</label>
                                        <ChromosomeList handleSelect={handleChromValueChange} chromosome={chromValue} />
                                    </div>
                                    <div className="form-group col-12 col-lg-4 col-md-12 mt-2 mt-lg-0">
                                        <label htmlFor="start-position" className="fw-bold">Start Position</label>
                                        <input
                                            type="text"
                                            className="form-control mt-2"
                                            id="start-position"
                                            value={startPosValue}
                                            onChange={(event) => handleStartPosValueChange(event)}
                                        />
                                    </div>
                                    <div className="form-group col-12 col-lg-4 col-md-12 mt-2 mt-lg-0">
                                        <label htmlFor="end-position" className="fw-bold">End Position</label>
                                        <input
                                            type="text"
                                            className="form-control mt-2"
                                            id="end-position"
                                            value={endPosValue}
                                            onChange={(event) => handleEndPosValueChange(event)}
                                        />
                                    </div>
                                </div>
                                {renderSearchButtons()}
                            </div>
                            <div className="form-text example-text text-muted mt-2 small">
                                Example: chr1:160,208,881-160,215,370
                            </div>
                        </div>
                    )}
                    {searchBy === 'gene' && (
                        <div className="gene-search-item w-100">
                            <div className="variant-search-ui-container d-flex align-items-end">
                                <div className="form-group mt-2 mt-lg-0 flex-grow-1">
                                    <label htmlFor="gene" className="fw-bold">Gene</label>
                                    <input
                                        type="text"
                                        className="form-control mt-2"
                                        id="gene"
                                        value={geneValue}
                                        placeholder='Enter gene symbols separated by commas (e.g. PEA15, TRAF3)'
                                        onChange={(event) => handleGeneValueChange(event)}
                                    />
                                </div>
                                {renderSearchButtons()}
                            </div>
                            <div className="form-text example-text text-muted mt-2 small">
                                Examples: PEA15, TRAF3
                            </div>
                        </div>
                    )}
                </div>
            </form>
            {fetchingData && <Spinner />}
            {!fetchingData && searchResults && searchResults.length ? (
                <SearchDataTable data={searchResults} />
            ) : null}
            {!fetchingData && searchMessage ? (
                <div className="search-message text-danger mt-4">{searchMessage}</div>
            ) : null}
            <About />
        </div>
    );
}

export default VariantBrowser;
