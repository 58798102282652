import React from 'react';

function Footer() {
    return (
        <div className="field field--name-body field--type-text-with-summary field--label-hidden field-item">
            <p>Questions? Contact the&nbsp;<a href="https://gregorconsortium.org/contact">GREGoR Data Coordinating Center</a>.</p>

            <p>Follow us on X <a href="https://twitter.com/GREGoR_research" className="ext" target="_blank"
                rel="noopener nofollow noreferrer">@GREGoR_research</a>.&nbsp;Follow us <a href="https://www.linkedin.com/company/gregor-consortium/" className="ext"
                target="_blank" rel="noopener nofollow noreferrer">on LinkedIn</a>.</p>

            <p>The GREGoR Consortium is funded by the National Human Genome Research Institute (NHGRI). Data Coordinating Center: <a href="https://reporter.nih.gov/project-details/10216104" className="ext"
                target="_blank" rel="noopener nofollow noreferrer">U24HG011746</a>; Research Centers: <a href="https://reporter.nih.gov/project-details/10217746" className="ext"
                target="_blank" rel="noopener nofollow noreferrer">U01HG011758</a>, <a href="https://reporter.nih.gov/project-details/10217658" className="ext"
                target="_blank" rel="noopener nofollow noreferrer">U01HG011755</a>, <a href="https://reporter.nih.gov/project-details/10215895" className="ext"
                target="_blank" rel="noopener nofollow noreferrer">U01HG011745</a>, <a href="https://reporter.nih.gov/project-details/10217842" className="ext"
                target="_blank" rel="noopener nofollow noreferrer">U01HG011762</a>, <a href="https://reporter.nih.gov/project-details/10215884" className="ext"
                target="_blank" rel="noopener nofollow noreferrer">U01HG011744</a>.</p>

            <p>©2021 - 2024&nbsp;
                <a href="http://www.biostat.washington.edu" target="_blank" className="ext"
                    rel="noopener nofollow noreferrer">University of Washington Department of Biostatistics</a> - <a href="http://www.washington.edu" target="_blank" className="ext"
                        rel="noopener nofollow noreferrer">University of Washington</a>, Seattle, WA<br/>
                <a href="https://www.washington.edu/accessibility/" className="ext" target="_blank"
                    rel="noopener nofollow noreferrer">Accessibility</a> | <a href="http://www.washington.edu/online/terms" target="_blank" className="ext"
                        rel="noopener nofollow noreferrer">Terms</a> | <a href="http://www.washington.edu/online/privacy" target="_blank" className="ext"
                        rel="noopener nofollow noreferrer">Privacy</a></p>
        </div>
    );
}

export default Footer;
