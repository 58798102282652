// utility function to convert json to tsv and download
export const jsonToTsv = (json, filename) => {
    // json array validation
    if (!Array.isArray(json) || json.length === 0) {
        console.error('Invalid input: Input should be a non-empty array.');
        return;
    }
    
    // extract keys from first object in the array to use as column headers
    const headers = Object.keys(json[0]);

    // Convert array of objects to an array of arrays with headers as the first row
    const rows = json.map(obj => headers.map(header => obj[header]));

    // put the headers at the beginning
    rows.unshift(headers);

    // convert the rows to *.tsv format
    const tsvContent = rows.map(row => row.join('\t')).join('\n');

    // create a blob from the *.tsv content
    const blob = new Blob([tsvContent], { type: 'text/tab-separated-values;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${filename}.tsv`;
    a.click();
};

// utility function to convert json to csv and download
export const jsonToCsv = (json, filename) => {
    // json array validation
    if (!Array.isArray(json) || json.length === 0) {
        console.error('Invalid input: Input should be a non-empty array.');
        return;
    }
    
    // extract keys from first object in the array to use as column headers
    const headers = Object.keys(json[0]);

    // Convert array of objects to an array of arrays with headers as the first row
    const rows = json.map(obj => headers.map(header => obj[header]));

    // put the headers at the beginning
    rows.unshift(headers);

    // convert the rows to *.csv format
    const csvContent = rows.map(row => row.map(value => `"${value}"`).join(',')).join('\n');

    // create a blob from the *.csv content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${filename}.csv`;
    a.click();
};
